import { StatusBar } from 'expo-status-bar';
import React, {useState} from 'react';
import { SafeAreaView, Text, View, Image, TextInput, KeyboardAvoidingView, Button, TouchableOpacity, Platform } from 'react-native';
import * as firebase from 'firebase';
import tailwind from 'tailwind-rn';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Crypto from "expo-crypto";
import * as AppleAuthentication from "expo-apple-authentication";
import * as GoogleSignIn from 'expo-google-sign-in';
import * as Updates from 'expo-updates';

const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be in the authorized domains list in the Firebase Console.
    url: 'https://exp6.riley.dev/?finishLogin=true',
    // This must be true.
    handleCodeInApp: true,
    iOS: {
      bundleId: 'dev.riley.exp6'
    },
    android: {
      packageName: 'com.example.android',
      installApp: true,
      minimumVersion: '12'
    },
    dynamicLinkDomain: 'exp6.riley.dev'
  };

let IS_SAVING_GUEST = false;
let GO_BACK_FUNCTION = null;

class LoginScreen extends React.Component {

    constructor(props) {
      super();

      this.state = {
        enteredEmail: "",
        sentEmail: false,
        appleLoginAvailable: false,
        savingGuest: props.savingGuest || false
      };

      if(props.savingGuest) {
          IS_SAVING_GUEST = true;
          GO_BACK_FUNCTION = props.cancelSavingGuest;
      }

      let linkToCheck = "";
      if(Platform.OS === "web") {
          linkToCheck = window.location.href;
      }

      // Confirm the link is a sign-in with email link.
      if (firebase.auth().isSignInWithEmailLink(linkToCheck)) {
        // Additional state parameters can also be passed via URL.
        // This can be used to continue the user's intended action before triggering
        // the sign-in operation.
        // Get the email if available. This should be available if the user completes
        // the flow on the same device where they started it.

        const checkLocalStorage = async () => {

            var email = await AsyncStorage.getItem('emailForSignIn');
            if (!email) {
                // User opened the link on a different device. To prevent session fixation
                // attacks, ask the user to provide the associated email again. For example:
                //email = window.prompt('Please provide your email for confirmation');
                console.log("UNHANDLED UNKNOWN EMAIL");
            }
            else {
            // The client SDK will parse the code from the link for you.
            firebase.auth().signInWithEmailLink(email, linkToCheck)
                .then((result) => {
                // Clear email from storage.
                    AsyncStorage.removeItem('emailForSignIn');
                    console.log("SUCCESSFUL SIGNIN");
                    console.log(result.user);
                    // You can access the new user via result.user
                    // Additional user info profile not available via:
                    // result.additionalUserInfo.profile == null
                    // You can check if the user is new or existing:
                    // result.additionalUserInfo.isNewUser
                })
                .catch((error) => {
                    // Some error occurred, you can inspect the code: error.code
                    // Common errors could be invalid email and invalid or expired OTPs.
                    console.error(error);
                });
            }
        }

        checkLocalStorage();
      }

      const checkAppleLogin = async() => {
        const loginAvailable = await AppleAuthentication.isAvailableAsync();
        this.setState({appleLoginAvailable: loginAvailable})

        await GoogleSignIn.initAsync().catch((e) => {

        });
        
      }

      if(Platform.OS !== "web") {
        checkAppleLogin();
      }
    }

    loginAsGuest () {
        firebase.auth().signInAnonymously();
    }

    async loginWithApple () {
        const csrf = Math.random().toString(36).substring(2, 15);
        const nonce = Math.random().toString(36).substring(2, 10);
        const hashedNonce = await Crypto.digestStringAsync(
        Crypto.CryptoDigestAlgorithm.SHA256, nonce);
        const appleCredential = await AppleAuthentication.signInAsync({
        requestedScopes: [
            AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
            AppleAuthentication.AppleAuthenticationScope.EMAIL
        ],
        state: csrf,
        nonce: hashedNonce
        }).catch((error) => {
            if(!error.contains("canceled")) alert(error);
        });
        const { identityToken, email, state } = appleCredential;

        if (identityToken) {
            const provider = new firebase.auth.OAuthProvider("apple.com");
            const credential = provider.credential({
                idToken: identityToken,
                rawNonce: nonce // nonce value from above
            });

            if(IS_SAVING_GUEST) {

                if(firebase.auth().currentUser) {
                    //Link the existing account to the new one.
                    firebase.auth().currentUser.linkWithCredential(credential).catch((error) => {
                        alert("UPGRADE ERROR: " + error);
                    }).then(() => {
                        GO_BACK_FUNCTION();
                    });
                }
                else {
                    alert('ERROR: Expected a current login but found none.');
                    return;
                }
            } else {
                await firebase.auth().signInWithCredential(credential).catch((error) => {
                    alert(error);
                })
            }
        }
    }

    async loginWithAppleWeb() {
        let provider = new firebase.auth.OAuthProvider('apple.com');
        provider.addScope('email');
        provider.addScope('name');
        firebase
        .auth()
        .signInWithPopup(provider)
        .then((result) => {
            console.log(result);
            /** @type {firebase.auth.OAuthCredential} */
            var credential = result.credential;

            // The signed-in user info.
            var user = result.user;

            // You can also get the Apple OAuth Access and ID Tokens.
            var accessToken = credential.accessToken;
            var idToken = credential.idToken;

            // ...
        })
        .catch((error) => {
            console.log(error);
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            // The email of the user's account used.
            var email = error.email;
            // The firebase.auth.AuthCredential type that was used.
            var credential = error.credential;

            // ...
        });
    }

    async loginWithGoogleWeb() {
        let provider = new firebase.auth.OAuthProvider('google.com');
        provider.addScope('email');
        firebase
        .auth()
        .signInWithPopup(provider)
        .then((result) => {
            console.log(result);
            /** @type {firebase.auth.OAuthCredential} */
            var credential = result.credential;

            // The signed-in user info.
            var user = result.user;

            // You can also get the Apple OAuth Access and ID Tokens.
            var accessToken = credential.accessToken;
            var idToken = credential.idToken;

            // ...
        })
        .catch((error) => {
            console.log(error);
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            // The email of the user's account used.
            var email = error.email;
            // The firebase.auth.AuthCredential type that was used.
            var credential = error.credential;

            // ...
        });
    }

    async loginWithGoogle() {  
        try {
            await GoogleSignIn.askForPlayServicesAsync();
            const { type, user } = await GoogleSignIn.signInAsync();
            if (type === 'success') {
                const credential = firebase.auth.GoogleAuthProvider.credential(user.auth.idToken);

                //No idea why we can't just use this.state.savingGuest here.
                if(IS_SAVING_GUEST) {

                    if(firebase.auth().currentUser) {
                        //Link the existing account to the new one.
                        firebase.auth().currentUser.linkWithCredential(credential).catch((error) => {
                            alert("UPGRADE ERROR: " + error);
                        }).then(() => {
                            GO_BACK_FUNCTION();
                        });
                    }
                    else {
                        alert('ERROR: Expected a current login but found none.');
                        return;
                    }
                } else {
                    firebase.auth().signInWithCredential(credential).catch((error) => {
                        alert("FIREBASE ERROR: " + error);
                    });
                }
            }
        } catch ({ message }) {
            alert('GOOGLE: Error:' + message);
        }
    }

    handleSignIn () {
      let email = this.state.enteredEmail;
      if(!email || email == "") return;
      this.setState({sentEmail: true});
      console.log("DOING SIGN IN WITH EMAIL " + email);
      firebase.auth().sendSignInLinkToEmail(email, actionCodeSettings)
        .then(() => {
          // The link was successfully sent. Inform the user.
          // Save the email locally so you don't need to ask the user for it again
          // if they open the link on the same device.
          AsyncStorage.setItem('emailForSignIn', email);
          // ...
        })
        .catch((error) => {
          var errorCode = error.code;
          var errorMessage = error.message;
          console.error(error);
          // ...
        });
    };
  
    setEnteredEmail (newEmail) {
      this.setState({enteredEmail: newEmail});
    }

    render() {

        let instructions;

        if(this.state.sentEmail) {
          instructions = (
            <View style={tailwind("w-full bg-white border border-gray-300 rounded-md p-3 mt-2")}>
              <Text style={tailwind("text-lg")}>Check your inbox...</Text>
              <Text style={tailwind("pt-3")}>Click on the link in the email message we just sent to you to automatically sign in and start playing.</Text>
              <TouchableOpacity onPress={() => this.setState({sentEmail: false})}><Text style={tailwind("pt-3 text-indigo-600")}>Didn't get a link?</Text></TouchableOpacity>
            </View>
          );
        } else {
          instructions = (
            <View style={tailwind("w-full")}>
              {/* <View style={tailwind("rounded-md w-full")}>
                  <TextInput style={tailwind("w-full px-3 py-2 border border-gray-300 text-gray-900 rounded-md text-base bg-white")} placeholder="Email Address" textContentType="username" keyboardType="email-address" autoCapitalize="none" onChangeText={text => this.setEnteredEmail(text)} value={this.state.enteredEmail} />
              </View>

              <TouchableOpacity onPress={() => this.handleSignIn()} style={tailwind("w-full py-2 px-4 mt-2 border border-transparent rounded-md bg-indigo-600")}><Text style={tailwind("text-white text-base font-medium")}>Sign In</Text></TouchableOpacity>
             */}
              {this.state.appleLoginAvailable === true &&
                <View style={{ alignItems: "center", paddingTop: 20 }}>
                    <AppleAuthentication.AppleAuthenticationButton
                    buttonType={AppleAuthentication.AppleAuthenticationButtonType.SIGN_IN}
                    buttonStyle={AppleAuthentication.AppleAuthenticationButtonStyle.BLACK}
                    cornerRadius={5}
                    style={{ width: 250, height: 50 }}
                    onPress={this.loginWithApple}
                /></View>
                }

                {Platform.OS !== "web" && 
                    <View style={{ alignItems: "center", paddingTop: 20 }}>
                        <TouchableOpacity onPress={this.loginWithGoogle}>
                            <Image source={require("./assets/signinwithgoogle.png")} style={{width: 210, height: 50}} />
                        </TouchableOpacity>
                    </View>
                }

                {Platform.OS === "web" && 
                <View style={{ alignItems: "center", paddingTop: 20 }}>
                    <TouchableOpacity onPress={this.loginWithAppleWeb}>
                        <Image source={require("./assets/apple-id-sign-in-with.png")} style={{width: 212, height: 38}} />
                    </TouchableOpacity>
                    <TouchableOpacity onPress={this.loginWithGoogleWeb} style={{marginTop: 20}}>
                        <Image source={require("./assets/signinwithgoogle.png")} style={{width: 210, height: 50}} />
                    </TouchableOpacity>
                </View>
                }

                {!this.state.savingGuest && Platform.OS !== "web" && 

                <View style={{ alignItems: "center", paddingTop: 20 }}>
                    <Button title="Sign in Later" onPress={this.loginAsGuest} />
                </View>

                }

                {this.state.savingGuest &&
                <View style={{ alignItems: "center", paddingTop: 20 }}>
                    <Button title="Cancel" onPress={() => {this.props.cancelSavingGuest()}} />
                </View>
                }
            </View>
          );
        }

        return (
            <KeyboardAvoidingView style={tailwind('h-full')} behavior="padding">
            <SafeAreaView style={tailwind('flex h-full items-center justify-center bg-gray-50')}>
            <View style={tailwind('w-full max-w-md items-center px-4')}>
                <Image source={{uri: "https://www.insidehighered.com/sites/default/server_files/styles/large/public/media/experiment.jpg?itok=7_rAAfqY", width: 200, height: 200}} alt={"Workflow"} />
                <Text h2 style={tailwind("mt-6 text-center text-3xl font-extrabold text-gray-900 pb-4")}>
                Sign in to your account
                </Text>
                <Text p style={tailwind("mt-2 text-center text-sm text-gray-600 pb-4")}>
                 {!this.state.savingGuest && 
                  <Text>Hail, and well met, adventurer!</Text>
                 }
                  {this.state.savingGuest && 
                    <Text>Login to your Apple or Google Account to permanently save your games.</Text>
                  }
                </Text>
                {instructions}
            </View>
            <View style={{position: "absolute", bottom: 0, alignContent: "center"}}>
                <Text style={tailwind("text-gray-300")}>version {Updates.updateId}</Text>
            </View>
            
            </SafeAreaView>
            </KeyboardAvoidingView>
        );
    }
}

export default LoginScreen;