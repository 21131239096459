import React, { useState, useCallback, useEffect } from 'react'
import { Image, View, StyleSheet, Text, Platform, TouchableOpacity, Dimensions, TextInput, Button, Switch } from 'react-native';
import ImageSelector from "./ImageSelector";
import tailwind from "tailwind-rn";
import Ionicons from 'react-native-vector-icons/Ionicons';

export default function EncounterCreator(props) {

    const [stage, setStage] = useState(1);
    const [newEncounterName, setNewEncounterName] = useState("");
    const [resetTokens, setResetTokens] = useState(false);

    const setEncounterImage = (newImage, defaultSize) => {
        props.callback(newImage, defaultSize, newEncounterName, resetTokens);
    }

    if(stage == 1) {

        return (
            
            <View
            style={{width: "100%", height: "100%", position: "absolute", backgroundColor: "rgba(255,255,255,0.65)", top: 0, left: 0, flex: 1, flexWrap: "wrap", flexDirection: "row", justifyContent: "center", alignContent: "center", zIndex: 20000}}
        >
            <View style={{position: "relative", backgroundColor: "#F1F1F1", borderRadius: 20, maxWidth: 420, minWidth: 300, marginLeft: 30, marginRight: 30, minHeight: 200, maxHeight: 500, padding: 20}}>
                <View>
                    <Text style={{paddingBottom: 5, fontSize: 20}}>Change Encounter</Text>
                    <TextInput placeholder={"Encounter Title"} autoFocus={true} value={newEncounterName} onChangeText={(newText) => setNewEncounterName(newText)} style={{marginBottom: 20, marginTop: 20, ...tailwind("w-full px-3 py-2 border border-gray-300 text-gray-900 rounded-md bg-white")}} />
                    <View>
                        <Text style={{paddingLeft: 70, fontSize: 14}}>Reset Tokens </Text>
                        <Switch style={{position: "relative", top: (Platform.OS === "ios" ? -25 : -18), left: 0}} value={resetTokens} onValueChange={(newValue) => setResetTokens(newValue)} />
                    </View>
                    <Button title="Next" onPress={() => setStage(2)} />
                </View>
                <TouchableOpacity style={{position: "absolute", top: 10, right: 10}} onPress={() => setEncounterImage(null)}>
                    <Ionicons name="close" style={{fontSize: 24}} />
                </TouchableOpacity>
            </View>
            </View>

        );
    } else if(stage == 2) {
        return (
            <ImageSelector selectorType={"background"} callback={setEncounterImage} />
        );
    }

}