import create from "zustand";

export const globalStore = create((set) => ({
    displayName: "Orrbain",
    userAvatar: "https://placeimg.com/140/140/any",
    userId: 100,
    gameId: "test",
    doRoll: () => {
        console.log("NO OP");
    },
    currentTokenSet: "isle",
    currentBgSet: "toonbg",
    showingEncounter: false
}));