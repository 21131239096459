import { StatusBar } from 'expo-status-bar';
import React, {useEffect, useState} from 'react';
import { StyleSheet, Text, View, Image, Dimensions, AppState, Platform } from 'react-native';
import Chat from "./Chat"
import { NavigationContainer } from '@react-navigation/native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import Ionicons from 'react-native-vector-icons/Ionicons';
import * as firebase from 'firebase';
import Character from "./Character"
import LoginScreen from './LoginScreen';
import Settings from "./Settings";
import * as Updates from 'expo-updates';
import {globalStore} from "./GlobalStore";
import { ActionSheetProvider } from '@expo/react-native-action-sheet'

function ChatScreen({navigation}) {
  return (
    <Chat navigation={navigation} />
  )
}

function CharacterScreen({navigation}) {
  return (
    <Character navigation={navigation} />
  )
}

function SettingsScreen({navigation}) {

  return (
    <Settings navigation={navigation} />
  )
}

const firebaseConfig = {
  apiKey: "AIzaSyA-23IgYAYuliVyDxfoA3vtobRdwR8eH0c",
  authDomain: "rpgtogo-ec3e6.firebaseapp.com",
  projectId: "rpgtogo-ec3e6",
  storageBucket: "rpgtogo-ec3e6.appspot.com",
  messagingSenderId: "916197555749",
  appId: "1:916197555749:web:d491f5f96daee310fb09cc",
  measurementId: "G-XD9Z4QTJ3T",
  databaseURL: "https://rpgtogo-ec3e6-default-rtdb.firebaseio.com/"
};

const Tab = createBottomTabNavigator()

export default function App() {

  if(Platform.OS !== "web" && Updates.updateId !== null) {

    useEffect(() => {

      const checkForUpdates = async () => {

        const {isAvailable} = await Updates.checkForUpdateAsync();
        if(isAvailable) {
          const {isNew} = await Updates.fetchUpdateAsync();
          if(isNew) {
            await Updates.reloadAsync();
          }
        }
      };

      checkForUpdates();

      AppState.addEventListener("change", state => {
        if(state == "active") {
          checkForUpdates();
        }
      });

    }, []);

  }

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  if(firebase.apps.length == 0) {
    console.log("Init firebase!");
    firebase.initializeApp(firebaseConfig);
    firebase.auth().onAuthStateChanged((user) => {
      console.log("FB AUTH IS NOW ");
      console.log(user);

      if(user) {
        setIsLoggedIn(true);
        globalStore.setState({
          userId: user.uid
        })
      } else {
        setIsLoggedIn(false);
      }
    });
  }

  if(isLoggedIn) {

  return (
      <ActionSheetProvider>
        <NavigationContainer>
          <Tab.Navigator screenOptions={({ route }) => ({
              tabBarIcon: ({ focused, color, size }) => {
                let iconName;

                if (route.name === 'Chat') {
                  iconName = focused
                    ? 'chatbubbles'
                    : 'chatbubbles';
                } else if (route.name === 'Character') {
                  iconName = focused ? 'person' : 'person';
                } else if(route.name === "Settings") {
                  iconName = "settings";
                }

                // You can return any component that you like here!
                return <Ionicons name={iconName} size={size} color={color} />;
              },
              headerShown: false,
            })} tabBarOptions={{showLabel: false}}>
            <Tab.Screen name="Chat" component={ChatScreen} />
            <Tab.Screen name="Character" component={CharacterScreen} />
            <Tab.Screen name="Settings" component={SettingsScreen} />
          </Tab.Navigator>
        </NavigationContainer>
      </ActionSheetProvider>
      
    );

  } else {
    return (
      <LoginScreen savingGuest={false} />
    )
  }
}