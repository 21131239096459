
import React, { useState, useCallback, useEffect } from 'react'
import { Image, View, StyleSheet, Text, Dimensions, TouchableOpacity } from 'react-native';
import Lightbox from 'react-native-lightbox'

export default function ImageMessage(props) {
    
    const styles = StyleSheet.create({
        container: {
            overflow: "visible"
        },
        image: {
            width: 150,
            height: 100,
            borderRadius: 13,
            margin: 3,
            resizeMode: 'cover',
        },
        imageActive: {
            flex: 1,
            resizeMode: 'contain',
        },
    })

    let styleOverride = {};

    if(props.currentMessage.mt === "desc") {
        styleOverride.width = 500;
        styleOverride.height = 300;
        styleOverride.maxWidth = "96%";
    }

    const showLightbox = () => {
        props.showLightbox(props.currentMessage.image)
    }

    return (

        <View style={[styles.container, props.containerStyle]}>
            
            <TouchableOpacity 
                onPress={() => showLightbox()}
            >
                <Image
                    {...props.imageProps}
                    style={[styles.image, styleOverride, props.imageStyle]}
                    source={{ uri: props.currentMessage.image }}
                />
            </TouchableOpacity>
          
        </View>
    )
}