import React, { useState, useCallback, useEffect, useRef } from 'react'
import {Audio} from "expo-av";
import { Image, View, StyleSheet, Text, TextInput, TouchableOpacity, Platform, Dimensions, Animated } from 'react-native';

function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
}

export default function RollingDice(props) {

    const textRef = React.createRef();

    const isRolling = !props.completed;
    const scaleAnim = useRef(new Animated.Value(1)).current;
    const [sound, setSound] = useState();
    const [completedSound, setCompletedSound] = useState();
    const [showBackground, setShowBackground] = useState(true);

    const changeNumberValue = () => {
        let newNum = getRandomInt(props.min, props.max + 1);
        if(!isRolling) {
            newNum = props.result;
        }
        if(newNum < 10) {
            newNum = "0" + newNum;
        } else {
            newNum = newNum.toString();
        }
        if(textRef.current) {
            textRef.current.setNativeProps({text: newNum})
            setTimeout(changeNumberValue, 50);
        }
    }
    
    setTimeout(changeNumberValue, 0)

    const pressDice = async () => {
        props.pressDice(props.groupid, props.id);
    }

    useEffect(() => {

        if(!completedSound) {

            async function loadCompletedSound() {
        
                const {sound: newSound} = await Audio.Sound.createAsync(
                    require('./assets/rollcomplete.wav')
                )
                setCompletedSound(newSound);
            }
            loadCompletedSound();

        }

        if(isRolling) {
            if(!sound) {
                async function loadSound() {
                    const {sound: newSound} = await Audio.Sound.createAsync(
                        require('./assets/rollingsfx.wav')
                    )
                    await newSound.setIsLoopingAsync(true);
                    setSound(newSound);
                    await newSound.playAsync();
                }
                loadSound();
            }
        } else {

            Animated.timing(scaleAnim, {
                toValue: 10,
                duration: 500,
                useNativeDriver: false
            }).start(() => {
                setShowBackground(false);
            });

            if(completedSound) {
                completedSound.playAsync();
            }

            if(sound) {
                sound.unloadAsync();
                setSound(null);
            }
            
        }
    }, [props.completed]);

    return (
        <View style={{width: 200, height: 200, flex: 1, maxHeight: 200, padding: 0, marginTop: 20, marginBottom: 20}}>
            {showBackground &&
            <Animated.View style={{borderRadius: 300, width: "100%", height: "100%", backgroundColor: "#d9c8e0", position: "absolute", top: 0, left: 0, transform: [{scaleX: scaleAnim}, {scaleY: scaleAnim}], opacity: scaleAnim.interpolate({
                inputRange: [1, 10],
                outputRange: [0.75, 0]
            })}}>
            </Animated.View>
            }
            <View style={{width: "100%", height: "100%", borderRadius: 300, overflow: "hidden"}}>
                <Image style={{width: "100%", height: 200, resizeMode: "cover"}} source={{uri: "https://static.wikia.nocookie.net/minecraft/images/d/d0/Fire3D.gif"}} />
                
                <TextInput style={{position: "absolute", top: 0, left: 0, fontSize: 100, color: "white", fontWeight: "bold", height: "100%", width: "100%", textShadowColor: "#777777", textShadowRadius: 6, textAlign: "center", textAlignVertical: "center"}} ref={textRef} value="1" editable={false} />
    
                <TextInput style={{position: "absolute", bottom: 0, left: 0, width: "100%", color: "black", backgroundColor: "white", paddingBottom: 5, opacity: 0.75, textAlign: "center"}} value={props.myRoll ? "Tap To Roll" : `${props.characterName}`} editable={false} />
            </View>
            

            
            <TouchableOpacity onPress={(props.myRoll ? pressDice : undefined)} style={{width: "100%", height: "100%", position: 'absolute', top: 0, left: 0}}></TouchableOpacity>
        </View>
            

    );
}
