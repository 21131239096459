
import React, { useState, useCallback, useEffect } from 'react'
import { Image, View, StyleSheet, Text, TextInput, TouchableOpacity, Platform } from 'react-native';
import Ionicons from 'react-native-vector-icons/Ionicons';

export default function CharacterAttribute(props) {

    const [editing, setEditing] = useState(false);
    const [currentValue, setCurrentValue] = useState(0);
    const [attributeName, setAttributeName] = useState("");
    const [rollMod, setRollMod] = useState("value");

    useEffect(() => {
        props.attribRef.child(props.attributeId).on("value", (snap) => {
            let newValues = snap.val();
            if(!newValues) {
                return; //Probably deleted.
            }
            setCurrentValue(newValues.currentValue);
            setAttributeName(newValues.attributeName);
            setRollMod(newValues.rollMod || "value");
            if(newValues.firstTime == true) {
                setEditing(true);
            }
        });

    }, [props.attributeId]);

    useEffect(() => {
        if(!props.keyboardOut && editing) {
            console.log("AUITO CLOSE");
            doSave();
        }
    }, [props.keyboardOut])

    const doLongPress = () => {
        setEditing(true);
    }

    const doPress = () => {
        if(calcedModString === "") return;
        props.doRoll("d20" + calcedModString, attributeName);
    }

    const doSave = () => {
        setEditing(false);
        props.attribRef.child(props.attributeId).set({
            currentValue: currentValue,
            attributeName: attributeName,
            rollMod: rollMod,
            firstTime: false
        });
    }

    const onBlur = () => {
        if(Platform.OS === "web") {
            setTimeout(() => {
                if(document.activeElement.tagName.toLowerCase() === "body") {
                    doSave();
                }
            }, 100);
        }
    }

    let numberStyle = {
        fontSize: 30,
        padding: 3
    }

    let calcedMod = parseInt(currentValue, 10);
    let calcedModString = "";

    if(Number.isInteger(calcedMod)) {

        if(rollMod === "half") {
            calcedMod = Math.floor(currentValue / 2);
        } else if (rollMod === "dnd") {
            calcedMod = Math.floor((currentValue - 10) / 2);
        }

        calcedModString = (calcedMod >= 0 ? "+" : "") + calcedMod;
    }

    return (
        <TouchableOpacity style={{minWidth: 160, maxWidth: 320, height: 115, marginRight: 10, marginBottom: 10, padding: 10, backgroundColor: "white", borderRadius: 10, flex: 1}}
            onPress={doPress}
            onLongPress={doLongPress}
            disabled={editing}
        >
            {editing &&
            <View>
            <TextInput value={currentValue} style={numberStyle} returnKeyType="done" onChangeText={(newText) => setCurrentValue(newText)} autoFocus={true} onBlur={onBlur} />
            <TextInput value={attributeName} style={{fontSize: 20, padding: 3}} returnKeyType="done" onChangeText={(newText) => setAttributeName(newText)} onBlur={onBlur} />
            {/* <Picker accessibilityLabel="Roll Mod" style={{marginTop: 5, fontSize: 12}} selectedValue={rollMod} onValueChange={(newValue) => setRollMod(newValue)}>
                <Picker.Item label="Full Value" value="value" />
                <Picker.Item label="5E Style" value="dnd" />
                <Picker.Item label="Half Value" value="half" />
            </Picker> */}
            <TouchableOpacity style={{position: "absolute", top: 0, right: 0}}
                onPress={doSave}    
            >
                <Ionicons style={{fontSize: 20}} name="checkmark-circle" />
            </TouchableOpacity>
            </View>
            }

            {!editing &&
            <View>
            <Text style={numberStyle}>{currentValue}</Text>
            <Text style={{fontSize: 20, padding: 3, height: 26}}>{attributeName}</Text>
            <Text style={{fontSize: 12, padding: 3, marginTop: 5, height: 20}}>{calcedModString}</Text>
            </View>
            }
                 
        </TouchableOpacity>
    )
}