
import React, { useState, useCallback, useEffect } from 'react'
import { Image, View, StyleSheet, Text, TouchableOpacity } from 'react-native';
import {globalStore} from "./GlobalStore";

export default function EncounterMessage(props) {
      

    return (
        <TouchableOpacity style={{position: "relative", marginBottom: 20, marginTop: 20}} onPress={(() => globalStore.setState({showingEncounter: true}))}>
            <Image style={{width: "100%", height: 200, resizeMode: "cover"}} source={{uri: props.currentMessage.image}} />
            <View style={{position: "absolute", bottom: 10, left: 10}}>
                <Text style={{fontSize: 16, color: "white", textShadowColor: "black", textShadowRadius: 5, fontStyle: "italic"}}>New Encounter:</Text>
                <Text style={{fontSize: 22, color: "white", textShadowColor: "black", textShadowRadius: 5, fontWeight: "bold"}}>{props.currentMessage.text}</Text>
            </View>
        </TouchableOpacity>
    )
}