import React, { useState, useCallback, useEffect } from 'react'
import { Image, View, StyleSheet, Text, Platform, TouchableOpacity } from 'react-native';
import * as firebase from 'firebase';
import tailwind from "tailwind-rn";
import LoginScreen from "./LoginScreen";
import * as Updates from 'expo-updates';

export default function Settings(props) {

    const [savingGuestAccount,setSavingGuestAccount] = useState(false);
    
    const doSignout = () => {
        firebase.auth().signOut();
    }

    const doSaveGuest = () => {
        setSavingGuestAccount(true);
    }

    const cancelSavingGuest = () => {
        setSavingGuestAccount(false);
    }

    if(savingGuestAccount) {
        return <LoginScreen savingGuest={true} cancelSavingGuest={cancelSavingGuest} />
    }

    else {

        return (
            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                <View>
                    <Text>Signed In As {firebase.auth().currentUser.email} ({firebase.auth().currentUser.uid})</Text>
                </View>
                {!firebase.auth().currentUser.email && 
                    <TouchableOpacity onPress={doSaveGuest}>
                    <   Text style={tailwind("text-indigo-600")}>Save Guest Account</Text>
                    </TouchableOpacity>
                }
                <TouchableOpacity onPress={doSignout}>
                    <Text style={{paddingTop: 20, ...tailwind("text-indigo-600")}}>Logout</Text>
                </TouchableOpacity>
                <Text style={{paddingTop: 20, ...tailwind("text-gray-600")}}>version {Updates.updateId}</Text>
            </View>
        );
    }
}

