import React, { useState, useCallback, useEffect, useRef } from 'react'
import { InputAccessoryView, Dimensions, StyleSheet, View, Button, ColorPropType, Text, Platform, SafeAreaView, KeyboardAvoidingView, TouchableOpacity, Image, TextInput } from 'react-native';
import { GiftedChat, Message, Bubble, Avatar, Send, MessageImage } from 'react-native-gifted-chat'
import Markdown from 'react-native-markdown-display';
import EncounterMessage from "./EncounterMessage"
import CurrentEncounter from "./CurrentEncounter"
import ImageMessage from "./ImageMessage"
import * as firebase from 'firebase';
import RollingDice from "./RollingDice";
import {Dice} from "dice-typescript";
import * as ImagePicker from 'expo-image-picker';
import * as Permissions from 'expo-permissions';
import uuid from 'uuid';
import {globalStore} from "./GlobalStore";
import {generateID} from "./GenerateID";
import EncounterCreator from "./EncounterCreator";

class CustomRandom {
  numberBetween(min, max) {
      let result = Math.ceil(Math.random() * max);
      globalSetRollingDice({
          min: min,
          max: max,
          result: result
      });
      return result;
  }
}

const dice = new Dice(null, new CustomRandom(), {
    maxRollTimes: 100,
    maxDiceSides: 100
});

let globalSetRollingDice = null;

async function uploadImageAsync(uri, gameId) {
  // Why are we using XMLHttpRequest? See:
  // https://github.com/expo/expo/issues/2402#issuecomment-443726662
  const blob = await new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = function() {
      resolve(xhr.response);
    };
    xhr.onerror = function(e) {
      console.log(e);
      reject(new TypeError('Network request failed'));
    };
    xhr.responseType = 'blob';
    xhr.open('GET', uri, true);
    xhr.send(null);
  });

  const ref = firebase
    .storage()
    .ref()
    .child(gameId)
    .child(uuid.v4());
  const snapshot = await ref.put(blob, {
    cacheControl: "public,max-age=43200"
  });

  return await snapshot.ref.getDownloadURL();
}

export default function Chat(globalProps) {

  const [messages, setMessages] = useState([]);
  const [messageType, setMessageType] = useState("ic");
  const [encounter, setEncounter] = useState(null);
  const [pendingDiceRolls, setPendingDiceRolls] = useState({});
  const [finishedDiceRolls, setFinishedDiceRolls] = useState([]);
  const [lightboxImage, setLightboxImage] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [showEncounterCreator, setShowEncounterCreator] = useState(false);

  const chatSystem = useRef();
  const sendButton = useRef();

  const myUserInfo = {
        _id: globalStore(state => state.userId),
        avatar: globalStore(state => state.userAvatar),
        name: globalStore(state => state.displayName)
    };

  const gameId = globalStore(state => state.gameId);

  let groupRollId = "";
  let numDiceInGroup = 0;
  let diceRollCharacterName = "";

  globalSetRollingDice = (newDice) => {
      if(numDiceInGroup > 3) return;
      let rollId = generateID();
      newDice._id = rollId;
      newDice.character = diceRollCharacterName;
      newDice.userId = myUserInfo._id;
      firebase.database().ref(`/games/${gameId}/pendingRolls/${groupRollId}/rolls/${rollId}`).set(newDice)
      numDiceInGroup++;
  }

  useEffect(() => {
    setMessages([
      {
        _id: 1,
        text: 'You enter the cavern. The _drip, drip, drip_ of the water cascades down the sides of the rock as you creep forward.',
        createdAt: new Date(),
        mt: "desc",
        user: {
          _id: 2,
          name: 'Orrbain',
          avatar: 'https://placeimg.com/140/140/any'
        },
      },
      {
        _id: 4,
        text: 'We gotta get out of here! Run!',
        createdAt: new Date(),
        mt: "ic",
        user: {
          _id: 2,
          name: 'Orrbain',
          avatar: 'https://placeimg.com/140/140/any'
        },
      },
      {
        _id: 5,
        text: 'This is very exciting!',
        createdAt: new Date(),
        mt: "ooc",
        user: {
          _id: 2,
          name: 'Orrbain',
          avatar: 'https://placeimg.com/140/140/any'
        },
      },
      {
        _id: 6,
        text: 'Runs for cover!',
        createdAt: new Date(),
        mt: "em",
        user: {
          _id: 2,
          name: 'Orrbain',
          avatar: 'https://placeimg.com/140/140/any'
        },
      },
      {
        _id: 2,
        image: "https://static.wikia.nocookie.net/mythological-supernatural-and-fantasy-creatures/images/5/5c/Ds_monsters_goblin_by_willowwisp.jpg/revision/latest/top-crop/width/360/height/450?cb=20170411195537",
        createdAt: new Date(),
        mt: "ic",
        user: {
          _id: 2,
          name: 'Orrbain',
          avatar: 'https://placeimg.com/140/140/any'
        },
      },
      {
          _id: 3,
          image: "https://cdna.artstation.com/p/assets/images/images/012/752/678/large/nurul-hidayat-done-ahh.jpg?1536329727",
          createdAt: new Date(),
          mt: "enc",
          user: {
            _id: 2,
            name: 'Orrbain',
            avatar: 'https://placeimg.com/140/140/any'
          }
      }
    ])


    firebase.database().ref(`/games/${gameId}/chatlog`).orderByKey().limitToLast(50)
    .once("value", (snap) => {
        let messageData = snap.val();
        let messageArray = [];
        for(var j in messageData) {
            messageData[j]._id = j;
            messageArray.push(messageData[j]);
        }
        let lastKey = null;
        if(messageArray.length > 0) {
            lastKey = messageArray[messageArray.length - 1]._id;
        }
        messageArray.reverse();
        setMessages(messageArray);

        listenForMessages(lastKey);
    });

    console.log("RE-BIND FIREBASE");

    const rollsRef = firebase.database().ref(`/games/${gameId}/pendingRolls`)
    rollsRef.on("value", (snap) =>{
      let newPendingRolls = snap.val() || {};

      setPendingDiceRolls(newPendingRolls);
    });

    const currentEncounterRef = firebase.database().ref(`/games/${gameId}/encounter`);
    currentEncounterRef.on("value", (snap) => {
      let newEncounter = snap.val() || {
        title: "Encounter Title",
        image: "https://cdn.conceptartempire.com/images/12/3212/00-23-cave-concept-art-environments.jpg"
      };
      setEncounter(newEncounter);
    });

    return () => {
      rollsRef.off("value");
      currentEncounterRef.off("value");
      firebase.database().ref(`/games/${gameId}/chatlog`).off("child_added");
    }

  }, [gameId])

  useEffect(() => {
    //Check for finished rolls.
    for(var groupId in pendingDiceRolls) {
      let allDone = true;
      for(var j in pendingDiceRolls[groupId].rolls) {
        if(finishedDiceRolls.indexOf(j) === -1) {
          allDone = false;
        }
      }
      if(allDone) {
        let diceResult = pendingDiceRolls[groupId].result;
        let messageId = generateID();

        firebase.database().ref(`/games/${gameId}/chatlog/${messageId}`).set({
            _id: messageId,
            createdAt: new Date(),
            mt: "em",
            text: "rolled **" + diceResult.total + "**\n " + diceResult.re + (diceResult.cmt ? "\n" + diceResult.cmt : ""),
            user: {
                _id: myUserInfo._id,
                name: diceResult.character || "",
                avatar: 'https://placeimg.com/140/140/any'
            }, 
        })

        firebase.database().ref(`/games/${gameId}/pendingRolls/${groupId}`).remove();
      }
    }

  }, [finishedDiceRolls]);


  const listenForMessages = (startingKey) => {
    let fbRef = firebase.database().ref(`/games/${gameId}/chatlog`)
    if(startingKey) {
        fbRef = fbRef.orderByKey().startAt(startingKey);
    }
    fbRef.on("child_added", (snap2) => {
        let singleMessageData = snap2.val();
        singleMessageData._id = snap2.key;
        if(singleMessageData._id === startingKey) {
            return;
        }
        setMessages(previousMessages => GiftedChat.append(previousMessages, singleMessageData));
    });
  }

  const onSend = (messages = []) => {
    messages.forEach(aMessage => {

        if(aMessage.text.substr(0, 2) === "/r" || aMessage.text.substr(0,5) === "/roll") {
          let rollString = aMessage.text.replace("/r ", "").replace("/roll ", "");
          doRoll(rollString, myUserInfo.name);
        } else {
          let messageId = aMessage._id;
          aMessage.mt = messageType;
          delete aMessage._id;

          let potentialUrl = aMessage.text.toLowerCase();
          
          let last = potentialUrl.search(/[?&]/);
          if (last !== -1) potentialUrl = potentialUrl.substring(0, last);
          let extension = potentialUrl.substr(potentialUrl.length-4, 4);
          let beginning = potentialUrl.substr(0, 4);

          if(beginning === "http" &&(extension === ".jpg" || extension === ".png" || extension === ".gif")) {
            aMessage.image = aMessage.text;
            aMessage.text = "";
          }

          firebase.database().ref(`/games/${gameId}/chatlog/${messageId}`).set(aMessage);
        }
    });
    //setMessages(previousMessages => GiftedChat.append(previousMessages, messages))

    
  };

  const doRoll = (rollExpression, characterName, commentText) => {
    groupRollId = generateID();
    numDiceInGroup = 0;
    diceRollCharacterName = characterName;

    let result = dice.roll(rollExpression);
    let messageValues = {
        re: result.renderedExpression,
        successes: result.successes,
        failures: result.failures,
        total: result.total,
        character: characterName,
    };
    if(commentText) {
      messageValues.cmt = commentText;
    }
    firebase.database().ref(`/games/${gameId}/pendingRolls/${groupRollId}/result`).set(messageValues);
  }

  globalStore.setState({
    doRoll: doRoll
  });

  const renderMessage = (props) => {

    let renderAvatar = undefined;

    if(props.currentMessage.mt == "desc") {
        renderAvatar = null;
    }

    if(props.currentMessage.mt == "enc") return <EncounterMessage {...props} />
    return <Message {...props} position={"left"} renderAvatar={renderAvatar} />
  }

  const renderMessageText = (props) => {
      //console.log(props.currentMessage);

    let styles = {};

    let thisText = props.currentMessage.text;

    if(props.currentMessage.mt == "desc") {
        styles.body = {
            color: "#666666"
        };
    } else if(props.currentMessage.mt == "em") {
        styles.body = {
            color: "#ab5d1a",
            fontStyle: "italic"

        }
        thisText = props.currentMessage.user.name + " " + props.currentMessage.text.charAt(0).toLowerCase() + props.currentMessage.text.substr(1);
    } else if(props.currentMessage.mt == "ooc") {
        styles.body = {
            color: "#1a65ab"
        }
        thisText = "(OOC) " + props.currentMessage.text;
    }

    return (
        <View style={{paddingLeft: 10, paddingRight: 10}}>
            <Markdown style={styles}>{thisText}</Markdown>
        </View>
    );
  }

  const renderMessageImage = (props) => {
    return (
      <ImageMessage {...props} showLightbox={showLightbox} />
    );
  }

  const renderBubble = (props) => {

    //console.log(props.currentMessage);

      const wrapperStyle = {
          left: {
            borderRadius: 0,
            backgroundColor: "transparent",
            marginRight: 0
          }
      };

      const containerStyle = {
          left: {
              alignItems: "flex-start"
          }
      };

      return (
          <Bubble {...props}
            wrapperStyle={wrapperStyle}
            containerStyle={containerStyle}
          />
      )
  }

  const getMessageId = () => {
      return generateID();
  }

  const renderActions = () => {
      return (
        <TouchableOpacity onPress={onPressActionButton} style={{position: "relative", bottom: 14, left: 5, paddingLeft: 2}}>
            <Text>⚡</Text>
        </TouchableOpacity>
      )
  }

  const onPressActionButton = () => {
    const options = [
      'Change Encounter',
      'Share Image',
      'Roll Dice',
      'Cancel'
    ];

    const cancelButtonIndex = options.length - 1;
    chatSystem && chatSystem.current && chatSystem.current._actionSheetRef && chatSystem.current._actionSheetRef._actionSheetRef 
      && chatSystem.current._actionSheetRef._actionSheetRef.current && 
      chatSystem.current._actionSheetRef._actionSheetRef.current.showActionSheetWithOptions({
        options,
        cancelButtonIndex
      },
      (buttonIndex) => {
        if(buttonIndex == 1) {
          pickImage();
        } else if(buttonIndex == 0) {
          setShowEncounterCreator(true);
        }
      });

  };

  let InputAccessoryStyle = {};

  const styles = StyleSheet.create({
    accessory: {
      width: "100%",
      height: 48,
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
      backgroundColor: '#F8F8F8',
      paddingHorizontal: 8
    }
  })

  let renderAccessory;

  if(Platform.OS === "web") {
      InputAccessoryStyle.display = "none";

      renderAccessory = () => {
    
        return (
            <View style={styles.accessory}>
                
                <TouchableOpacity
                    style={{paddingRight: 10}}
                    onPress={() => setMessageType("ic")}
                disabled={messageType == "ic" ? true : false}
                ><Text style={messageType == "ic" ? {fontWeight: "bold"} : {}}>Say</Text></TouchableOpacity>
                
                <TouchableOpacity
                    style={{paddingRight: 10}}
                    onPress={() => setMessageType("em")}
                disabled={messageType == "em" ? true : false}
                ><Text style={messageType == "em" ? {fontWeight: "bold"} : {}}>Emote</Text></TouchableOpacity>

                <TouchableOpacity
                    style={{paddingRight: 10}}
                    onPress={() => setMessageType("desc")}
                disabled={messageType == "desc" ? true : false}
                ><Text style={messageType == "desc" ? {fontWeight: "bold"} : {}}>Describe</Text></TouchableOpacity>

                <TouchableOpacity
                    style={{paddingRight: 10}}
                    onPress={() => setMessageType("ooc")}
                disabled={messageType == "ooc" ? true : false}
                ><Text style={messageType == "ooc" ? {fontWeight: "bold"} : {}}>OOC</Text></TouchableOpacity>
               
            </View>
        );
      }
  }

  const renderSend = (sendProps) => {
    return <Send ref={sendButton} {...sendProps} />
  }

  const handleKeyDown = function(e) {
    
    if(e.keyCode === 13 && !e.shiftKey) {
      sendButton && sendButton.current && sendButton.current.handleOnPress();
    }
  }

  const pickImage = async () => {
    await Permissions.askAsync(Permissions.MEDIA_LIBRARY);
    let pickerResult = await ImagePicker.launchImageLibraryAsync({
      allowsEditing: false
    });

    handleImagePicked(pickerResult);
  };

  const handleImagePicked = async pickerResult => {
    try {
      if(!pickerResult.cancelled) {
        setIsUploading(true);
        const uploadUrl = await uploadImageAsync(pickerResult.uri, gameId);

        const messageId = generateID();

        firebase.database().ref(`/games/${gameId}/chatlog/${messageId}`).set({
            createdAt: new Date(),
            mt: messageType,
            text: "",
            image: uploadUrl,
            user: {
                _id: myUserInfo._id,
                name: myUserInfo.name,
                avatar: myUserInfo.avatar
            }, 
        });

      }

    }
    catch (e) {
      console.log(e);
      alert('Error uploading image.');
    } finally {
      setIsUploading(false);
    }
  };

  const showLightbox = (lightboxImage) => {
    setLightboxImage(lightboxImage);
  }

  const setEncounterImage = (newImage, defaultSize, newTitle, resetTokens) => {
    if(newImage) {

      const finalTitle = (newTitle && newTitle !== "" ? newTitle : encounter.title);

      firebase.database().ref(`/games/${gameId}/encounter`).update({
        image: newImage,
        title: finalTitle
      });

      if(resetTokens) {
        firebase.database().ref(`/games/${gameId}/encounterTokens`).remove();
      }

      const messageId = generateID();

      firebase.database().ref(`/games/${gameId}/chatlog/${messageId}`).set({
          createdAt: new Date(),
          mt: "enc",
          image: newImage,
          text: finalTitle,
          user: {
              _id: myUserInfo._id,
              name: myUserInfo.name,
              avatar: myUserInfo.avatar
          }, 
      });
    }

    setShowEncounterCreator(false);
    
  }

  const renderFooter = () => {

    let footerMessage = null;

    if(isUploading) {
      footerMessage = "Uploading...";
    }

    if(footerMessage) {
      return(
        <View>
          <Text style={{color: "#999999", fontSize: 14, paddingBottom: 5, paddingLeft: 5}}>{footerMessage}</Text>
        </View>
        
      )
    } else {
      return null;
    }
  }

  const pressDice = (groupId, rollKey) => {

    firebase.database().ref(`/games/${gameId}/pendingRolls/${groupId}/rolls/${rollKey}`).child("completed").set(true);
    
    setTimeout(() => {
      setFinishedDiceRolls((previousDiceRolls) => {
        if(previousDiceRolls.indexOf(rollKey) === -1) {
          return [...previousDiceRolls, rollKey];
        } else {
          return previousDiceRolls;
        }
      })
    }, 2000);

  };
  const rollingDiceListing = [];

  let displayedDice = 0;

  for(var groupId in pendingDiceRolls) {
    for(var rollId in pendingDiceRolls[groupId].rolls) {
      if(displayedDice >= 4) {
        break;
      }
      let aRoll = pendingDiceRolls[groupId].rolls[rollId];
      rollingDiceListing.push(
        <RollingDice pressDice={pressDice} key={rollId} groupid={groupId} id={rollId} max={aRoll.max} min={aRoll.min} result={aRoll.result} completed={aRoll.completed} characterName={aRoll.character} myRoll={aRoll.userId=== myUserInfo._id ? true : false} />
      );
      displayedDice++;
    }
  }

  return (
    <SafeAreaView style={{flex: 1}}>
        {Platform.OS === "ios" &&
        <InputAccessoryView nativeID="ChatType" style={InputAccessoryStyle}>
            <View style={styles.accessory}>
                <Button
                    onPress={() => setMessageType("ic")}
                title="Say"
                disabled={messageType == "ic" ? true : false}
                />
                <Button
                    onPress={() => setMessageType("em")}
                title="Emote"
                disabled={messageType == "em" ? true : false}
                />
                <Button
                    onPress={() => setMessageType("desc")}
                title="Describe"
                disabled={messageType == "desc" ? true : false}
                />
                <Button
                    onPress={() => setMessageType("ooc")}
                title="OOC"
                disabled={messageType == "ooc" ? true : false}
                />
            </View>
        </InputAccessoryView>
        }
        {encounter &&
        <CurrentEncounter encounterTitle={encounter.title} encounterImage={encounter.image} ></CurrentEncounter>
        }
        <GiftedChat
        messages={messages}
        onSend={messages => onSend(messages)}
        user={myUserInfo}
        textInputProps={{inputAccessoryViewID: "ChatType", onKeyPress: (Platform.OS === "web" ? handleKeyDown : undefined)}}
        renderMessage={renderMessage}
        renderMessageText={renderMessageText}
        renderMessageImage={renderMessageImage}
        renderBubble={renderBubble}
        showUserAvatar={true}
        isKeyboardInternallyHandled={false}
        showAvatarForEveryMessage={true}
        messageIdGenerator={getMessageId}
        renderActions={renderActions}
        renderAccessory={Platform.OS !== "ios" ? renderAccessory : undefined}
        ref={chatSystem}
        renderSend={renderSend}
        renderChatFooter={renderFooter}
        />
        {Platform.OS !== "web" &&
        <KeyboardAvoidingView behavior={Platform.OS === "ios" ? "padding" : "position"}></KeyboardAvoidingView>
        }
        {Object.keys(pendingDiceRolls).length > 0 &&
        <View
        style={{width: Dimensions.get("window").width, height: Dimensions.get("window").height, position: "absolute", backgroundColor: "transparent", top: 0, left: 0, flex: 1, flexWrap: "wrap", flexDirection: "column", justifyContent: "center", alignContent: "center", zIndex: 20000}}
    >
            {rollingDiceListing}
        </View>
        }

        {showEncounterCreator &&
          <EncounterCreator callback={setEncounterImage} />
        }

        {lightboxImage !== "" &&

        <View
        style={{width: Dimensions.get("window").width, height: Dimensions.get("window").height, position: "absolute", backgroundColor: "black", top: 0, left: 0, flex: 1, flexWrap: "wrap", flexDirection: "row", justifyContent: "center", alignContent: "center", zIndex: 10000}}
        >
            <TouchableOpacity onPress={() => setLightboxImage("")}>
            <Image
                style={
                  Platform.OS === "web" ?
                  {width: Dimensions.get("window").width, height: Dimensions.get("window").height, resizeMode: "contain"}
                  :
                  {width: Dimensions.get("window").height-100, height: Dimensions.get("window").width, resizeMode: "contain", transform: [{rotate: "90deg"}]}
                }
                source={{ uri: lightboxImage }}
            />
            </TouchableOpacity>
        </View>

      }
        
    </SafeAreaView>
  )
}