
import React, { useState, useCallback, useEffect } from 'react'
import { Image, View, StyleSheet, Text, Platform, TouchableOpacity, Dimensions } from 'react-native';
import Encounter from "./Encounter";
import * as firebase from "firebase";
import Ionicons from 'react-native-vector-icons/Ionicons';
import {globalStore} from "./GlobalStore";

export default function CurrentEncounter(props) {

    const gameId = globalStore(state => state.gameId);
    const showingEncounter = globalStore(state => state.showingEncounter);

    const setShowingEncounter = (newValue) => {
        globalStore.setState({
            showingEncounter: newValue
        });
    }

    let baseHeight = 50;
    let paddingTop = 0;
    let paddingBottom = 0;
    if(Platform.OS === "ios") {
        paddingTop = 46;
        paddingBottom = 30;
    }

    let encounterTop = -10000;
    if(showingEncounter) {
        encounterTop = 0;
    }

    return (
        <View style={{position: "absolute", top: 0, left: 0, width: "100%", height: baseHeight + 2, marginTop: paddingTop, zIndex: 10000, backgroundColor: "black", overflow: "visible"}}>
            <TouchableOpacity onPress={() => setShowingEncounter(true)}>
                <Image style={{width: "100%", height: baseHeight, resizeMode: "cover"}} source={{uri: props.encounterImage}} />
                <Text style={{position: "absolute", bottom: 10, left: 10, fontSize: 22, color: "white", textShadowColor: "black", textShadowRadius: 5}}>{props.encounterTitle}</Text>
            </TouchableOpacity>
            
            <View style={{position: "absolute", top: encounterTop, left: 0, width: "100%", height: Dimensions.get("window").height - baseHeight - paddingTop - paddingBottom}}>
                <Encounter encounterImage={props.encounterImage} encounterTitle={props.encounterTitle} bottomPadding={(baseHeight + paddingTop + paddingBottom)} />
                <TouchableOpacity style={{position: "absolute", top: 8, right: 10}} onPress={() => setShowingEncounter(false)}>
                    <Ionicons name="close" style={{color: "white", fontSize: 24}} />
                </TouchableOpacity>
            </View>
        </View>
    )
}