
import React, { useState, useCallback, useEffect, useRef } from 'react'
import { Image, View, StyleSheet, Text, Platform, TouchableOpacity, Dimensions, Animated, PanResponder, Button, TextInput } from 'react-native';
import * as firebase from "firebase";
import Ionicons from 'react-native-vector-icons/Ionicons';
import tailwind from "tailwind-rn";

export default function EncounterToken(props) {

    const percentToPixels = (pct, dimension) => {
        if(pct === null) {
            return 0;
        }
        if(dimension === "width") {
            return Math.floor(pct * Dimensions.get("window").width);
        } else {
            return Math.floor(pct * (Dimensions.get("window").height - props.bottomPadding));
        }
    };

    const pixelsToPercent = (pixels, dimension) => {
        if(dimension === "width") {
            return pixels / Dimensions.get("window").width;
        } else {
            return pixels / (Dimensions.get("window").height - props.bottomPadding)
        }
    }

    const [pan, setPan] = useState(new Animated.ValueXY({x: percentToPixels(props.posLeft, "width"), y: percentToPixels(props.posTop, "height")}))
    const [currentPos, setCurrentPos] = useState({x: percentToPixels(props.posLeft, "width"), y: percentToPixels(props.posTop, "height")})
    const [showOptions, setShowOptions] = useState(false);
    const [tokenTempName, setTokenTempName] = useState(props.tokenName || "");
    const [tokenTempNote, setTokenTempNote] = useState(props.tokenNote || "");

    let tokenWidth = 75;
    let tokenHeight = 75;
    if(props.tokenSize == "s") {
        tokenWidth = 35;
        tokenHeight = 35;
    } else if(props.tokenSize == "l") {
        tokenWidth = 125;
        tokenHeight = 125;
    } else if(props.tokenSize == "xl") {
        tokenWidth = 200;
        tokenHeight = 200;
    }

    const trashIconRef = useRef();

    let startPressTime = 0;
    let longPressTimeout = null;

    const panResponder = PanResponder.create({
        onStartShouldSetPanResponder: () => {
            if(showOptions) return false;
            return true;
        },
        onPanResponderStart: (e, gesture) => {
            longPressTimeout = setTimeout(() => {
                longPressTimeout = null;
                console.log("LONG PRESS GO");
                setShowOptions(true);
            }, 1500);
        },
        onPanResponderMove: (e, gesture) => {

            if(gesture.dx > 5 || gesture.dy > 5) {
                if(longPressTimeout) {
                    clearTimeout(longPressTimeout);
                    longPressTimeout = null;
                }
            }

            pan.setValue({x: gesture.dx + currentPos.x, y: gesture.dy + currentPos.y})

            if((gesture.dy + currentPos.y) - (Dimensions.get("window").height - props.bottomPadding) > -60) {
                trashIconRef && trashIconRef.current && trashIconRef.current.setNativeProps({style: {display: "block"}})
            } else {
                trashIconRef && trashIconRef.current && trashIconRef.current.setNativeProps({style: {display: "none"}})
            }
        },
        onPanResponderRelease: (e, gesture) => {

            if(longPressTimeout) {
                clearTimeout(longPressTimeout);
                longPressTimeout = null;
            }

            let newX = currentPos.x + gesture.dx;
            let newY = currentPos.y + gesture.dy

            setCurrentPos({
                x: newX,
                y: newY
            })

            if(newY - (Dimensions.get("window").height - props.bottomPadding) > -60) {
                firebase
                    .database()
                    .ref(`/games/${props.gameId}/encounterTokens/${props.tokenId}`)
                    .remove();
            } else {

                firebase
                    .database()
                    .ref(`/games/${props.gameId}/encounterTokens/${props.tokenId}`)
                    .update({
                        x: Math.round(pixelsToPercent(newX, "width") * 10000) / 10000,
                        y: Math.round(pixelsToPercent(newY, "height") * 10000) / 10000
                    });

            }
        }
    });

    const adjustPositioning = () => {
        let newValues = {x: percentToPixels(props.posLeft, "width"), y: percentToPixels(props.posTop, "height")};

        pan.setValue({
            x: newValues.x,
            y: newValues.y
        });
        setCurrentPos({
            x: newValues.x,
            y: newValues.y
        });
    }

    const setTokenSize = (newSize) => {
        closeOptions();
        firebase
            .database()
            .ref(`/games/${props.gameId}/encounterTokens/${props.tokenId}`)
            .update({
                s: newSize
            });
    }

    const closeOptions = () => {
        setShowOptions(false);
        firebase
            .database()
            .ref(`/games/${props.gameId}/encounterTokens/${props.tokenId}`)
            .update({
                n: tokenTempName,
                o: tokenTempNote
            });
    }

    if(Platform.OS === "web") {

        useEffect(() => {
            Dimensions.addEventListener("change", () => {
                adjustPositioning();
            });
        }, []);

    }

    let windowX = 0;
    let windowY = 0;
    if(showOptions) {
        windowX = currentPos.x - 75;
        windowY = currentPos.y - 75;

        if(windowX < 100) {
            windowX = 100;
        } else if(windowX > Dimensions.get("window").width - 250) {
            windowX = Dimensions.get("window").width - 250;
        }

        const maxY = Platform.OS === "web" ? 250 : 650; //for keyboard avoidance

        if(windowY < 100) {
            windowY = 100;
        } else if(windowY > Dimensions.get("window").height - maxY) {
            windowY = Dimensions.get("window").height - maxY;
        }

        windowX = windowX - currentPos.x;
        windowY = windowY - currentPos.y;
    }

    const showName = (props.tokenName && props.tokenName !== "" ? true : false)

    useEffect(() => {

        adjustPositioning();
        
    }, [props.posLeft, props.posTop])

    return (
        <Animated.View {...panResponder.panHandlers} style={[pan.getLayout(), {position: "absolute", zIndex: (showOptions ? 10000 : undefined)}]}>
            <Image source={{uri: props.tokenImage}} style={{width: tokenWidth, height: tokenHeight, resizeMode: "cover", borderRadius: tokenWidth}} />
            {showName &&
            <Text selectable={false} style={{position: "absolute", bottom: -20, left: 0, textAlign: "center", minWidth: "100%", color: "black", fontWeight: "bold", fontSize: 14, backgroundColor: "white", padding: 1, borderRadius: 5}}>{props.tokenName}</Text>
            }
            <Ionicons name="trash" style={{fontSize: 40, position: "absolute", top: -15, left: -15, color: "white", textShadowColor: "black", textShadowRadius: 5, display: "none"}} ref={trashIconRef} />
            {showOptions &&
                <View style={{position: "absolute", top: windowY, left: windowX, width: 200, height: 200, padding: 20, backgroundColor: "white", borderRadius: 20}}>
                    <Text style={{marginBottom: 10}}>Token Size:</Text>
                    <View style={{flex: 1, flexDirection: "row", height: 40, maxHeight: 40}}>
                        <View style={{flex: 1, maxHeight: 40, marginRight: 5}}><Button title="S" onPress={() => setTokenSize("s")} /></View>
                        <View style={{flex: 1, maxHeight: 40, marginRight: 5}}><Button title="M" onPress={() => setTokenSize("m")}/></View>
                        <View style={{flex: 1, maxHeight: 40, marginRight: 5}}><Button title="L" onPress={() => setTokenSize("l")}/></View>
                        <View style={{flex: 1, maxHeight: 40}}><Button title="XL" onPress={() => setTokenSize("xl")}/></View>
                    </View>
                    <TextInput placeholder={"Token Name"} value={tokenTempName} onChangeText={(newText) => setTokenTempName(newText)} style={{marginTop: 10, marginBottom: 5, ...tailwind("w-full px-3 py-2 border border-gray-300 text-gray-900 rounded-md bg-white")}} />
                    <TextInput placeholder={"Note"} value={tokenTempNote} onChangeText={(newText) => setTokenTempNote(newText)} style={{...tailwind("w-full px-3 py-2 border border-gray-300 text-gray-900 rounded-md bg-white")}} />
                    <TouchableOpacity style={{position: "absolute", top: -10, right: -10, backgroundColor: "white", borderRadius: 20}} onPress={() => closeOptions()}>
                        <Ionicons name="close-circle" style={{fontSize: 30, color: "black"}} />
                    </TouchableOpacity>

                </View>
            }
        </Animated.View>
    )

}