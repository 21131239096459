
import React, { useState, useCallback, useEffect, useRef } from 'react'
import { Image, View, StyleSheet, Text, TextInput, SafeAreaView, TouchableOpacity, KeyboardAvoidingView, ScrollView, Dimensions, Platform, Keyboard } from 'react-native';
import tailwind from 'tailwind-rn';
import CharacterAttribute from "./CharacterAttribute"
import Ionicons from 'react-native-vector-icons/Ionicons';
import * as firebase from 'firebase';
import { render } from 'react-dom';
import {globalStore} from "./GlobalStore";

export default function Character(props) {

    const characterId = props.characterId || "testCharacter";
    const gameId = globalStore(state => state.gameId);

    const [attributes, setAttributes] = useState([]);
    const [characterName, setCharacterName] = useState("");
    const [keyboardOut, setKeyboardOut] = useState(false);

    const rollRef = useRef(globalStore.getState().doRoll)
    // Connect to the store on mount, disconnect on unmount, catch state-changes in a reference
    useEffect(() => globalStore.subscribe(
        doRoll => (rollRef.current = doRoll), 
        state => state.doRoll
    ), []);

    let fbRef = firebase.database().ref(`/games/${gameId}/characters/${characterId}`);
    let attribRef = fbRef.child("attributes");

    useEffect(() => {
        attribRef.on("child_added", (snap) => {
            //setAttributes([...attributes, snap.key]);
            setAttributes((oldArray) => {
                if(oldArray.indexOf(snap.key) === -1) {
                    return [...oldArray, snap.key];
                } else {
                    return oldArray;
                }
            })
        });

        fbRef.child("name").on("value", (snap) => {
            setCharacterName(snap.val());
        });

        const keyboardDidHideListener = Keyboard.addListener(
            'keyboardDidHide',
            () => {
                setKeyboardOut(false);
            }
        );

        const keyboardDidShowListener = Keyboard.addListener(
            'keyboardDidShow',
            () => {
                setKeyboardOut(true);
            }
        );

        return () => {
            keyboardDidHideListener.remove();
            keyboardDidShowListener.remove();
        };
    }, [props.characterId]);

    const newAttribute = () => {
        attribRef.push({
            attributeName: "New",
            currentValue: "10",
            rollMod: "value",
            firstTime: true
        })
    }

    const saveCharacterName = () => {
        fbRef.child("name").set(characterName);
    }

    const doRoll = (rollExpression, commentText) => {
        console.log("ATTRIBUTE ROLL");
        console.log(characterName);
        rollRef.current(rollExpression, characterName, commentText);
        props.navigation.navigate("Chat");
    }

    const attributeListing = attributes.map((anAttribute) =>
        <CharacterAttribute key={anAttribute} attributeId={anAttribute} attribRef={attribRef} doRoll={doRoll} keyboardOut={keyboardOut} />
    );

    return (
        <SafeAreaView style={{height: "100%"}}>
            <KeyboardAvoidingView behavior={Platform.OS === "ios" ? "padding" : "height"} style={{height: "100%"}}>
            <ScrollView style={{paddingTop: 20, paddingLeft: 20, paddingRight: 20, height: "100%"}}>
                <TextInput value={characterName} style={{fontSize: 30, marginBottom: 20}} returnKeyType="done" onChangeText={(newText) => setCharacterName(newText)} onBlur={saveCharacterName} />
                <View style={{paddingBottom: 20}}>
                    <Text style={{position: "absolute", right: 0, top: 6, fontSize: 14, ...tailwind("text-gray-400")}}>Tap to Roll, Press and Hold to Edit</Text>
                    <Text style={{fontSize: 22}}>Attributes</Text>
                </View>
                <View style={{flex: 1, flexDirection: "row", flexWrap: "wrap"}}>
                    {attributeListing}
                    <TouchableOpacity style={{minWidth: 160, maxWidth: 166, height: 115, marginRight: 10, marginBottom: 10, padding: 10, backgroundColor: "white", borderRadius: 10, flex: 1, alignItems: "center", justifyContent: "center"}} onPress={newAttribute}>
                        <Ionicons style={{fontSize: 60}} name="add" />
                    </TouchableOpacity>
                </View>
                <Text style={{fontSize: 20, paddingBottom: 20, paddingTop: 20}}>Actions</Text>
                <View style={{paddingTop: 40}}></View>
                
            </ScrollView>
            </KeyboardAvoidingView>
        </SafeAreaView>
    )
}