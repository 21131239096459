
import React, { useState, useCallback, useEffect } from 'react'
import { Image, View, StyleSheet, Text, Dimensions, TouchableOpacity, ScrollView, Button } from 'react-native';
import tailwind from 'tailwind-rn';
import EncounterToken from "./EncounterToken";
import * as firebase from "firebase";
import Ionicons from 'react-native-vector-icons/Ionicons';
import ImageSelector from "./ImageSelector";
import {globalStore} from "./GlobalStore";



export default function Encounter(props) {

    const [encounterTokens, setEncounterTokens] = useState({});
    const [showTokenAdd, setShowTokenAdd] = useState(false);

    const gameId = globalStore(state => state.gameId);
    
    useEffect(() => {

        const tokenRef = firebase.database().ref(`/games/${gameId}/encounterTokens`);

        tokenRef.on("value", (snap) => {
            setEncounterTokens(snap.val());
        });

        return () => {
            tokenRef.off("value");
        }

    }, []);

    const imageSelectorCallback = (imageUrl, defaultSize) => {
        if(imageUrl !== null) {
            addToken(imageUrl, defaultSize);
        }
        setShowTokenAdd(false);
    }

    const addToken = (imageUrl, defaultSize) => {
        firebase
            .database()
            .ref(`/games/${gameId}/encounterTokens`)
            .push({
                x: 0.10,
                y: 0.10,
                image: imageUrl,
                s: defaultSize || "m"
            });
    }
    
      
    let tokensListing = [];

    for(let j in encounterTokens) {
        tokensListing.push(
            <EncounterToken key={j} tokenImage={encounterTokens[j].image} posLeft={encounterTokens[j].x} posTop={encounterTokens[j].y} gameId={gameId} tokenId={j} bottomPadding={props.bottomPadding} tokenSize={encounterTokens[j].s} tokenName={encounterTokens[j].n} tokenNote={encounterTokens[j].o} />
        )
    }

    return (
        <View style={{width: "100%", height: "100%", backgroundColor: "black", position: "relative"}}>
            <Image source={{uri: props.encounterImage}} style={{resizeMode: "cover", width: "100%", height: "100%"}} />
            {tokensListing}
            <Text style={{position: "absolute", top: 10, left: 10, fontSize: 22, color: "white", textShadowColor: "black", textShadowRadius: 5}}>{props.encounterTitle}</Text>
            <TouchableOpacity style={{position: "absolute", bottom: 15, right: 15}} onPress={() => {setShowTokenAdd(true); }}>
                <Ionicons name="add-circle" style={{color: "white", fontSize: 40}} />
            </TouchableOpacity>
            {showTokenAdd &&
                <ImageSelector callback={imageSelectorCallback} />    
            }
        </View>
    )
}