import React, { useState, useCallback, useEffect } from 'react'
import { Image, View, StyleSheet, Text, ScrollView, TouchableOpacity, Button, TextInput } from 'react-native';
import * as firebase from "firebase";
import Ionicons from 'react-native-vector-icons/Ionicons';
import TOKEN_SETS from "./TokenSets";
import * as ImagePicker from 'expo-image-picker';
import * as Permissions from 'expo-permissions';
import uuid from 'uuid';
import tailwind from "tailwind-rn";
import {globalStore} from "./GlobalStore";
import { useActionSheet } from '@expo/react-native-action-sheet'

async function uploadImageAsync(uri, gameId) {
    // Why are we using XMLHttpRequest? See:
    // https://github.com/expo/expo/issues/2402#issuecomment-443726662
    const blob = await new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function() {
        resolve(xhr.response);
      };
      xhr.onerror = function(e) {
        console.log(e);
        reject(new TypeError('Network request failed'));
      };
      xhr.responseType = 'blob';
      xhr.open('GET', uri, true);
      xhr.send(null);
    });
  
    const ref = firebase
      .storage()
      .ref()
      .child(gameId)
      .child(uuid.v4());
    const snapshot = await ref.put(blob, {
      cacheControl: "public,max-age=43200"
    });
  
    return await snapshot.ref.getDownloadURL();
  }

export default function ImageSelector(props) {

    const [tokenAddType, setTokenAddType] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [urlText, setUrlText] = useState("");
    const [urlError, setUrlError] = useState(null);
    const selectorType = props.selectorType || "token";
    let currentTokenSet = "";
    if(selectorType === "background") {
        currentTokenSet = globalStore(state => state.currentBgSet);
    } else {
        currentTokenSet = globalStore(state => state.currentTokenSet);
    }
    

    const gameId = globalStore(state => state.gameId);

    const { showActionSheetWithOptions } = useActionSheet();

    let libraryListing = [];

    const finishSelection = (imageUrl, defaultSize) => {
        props.callback(imageUrl, defaultSize);
    }

    const pickImage = async () => {
        await Permissions.askAsync(Permissions.MEDIA_LIBRARY);
        let pickerResult = await ImagePicker.launchImageLibraryAsync({
            allowsEditing: false
        });

        handleImagePicked(pickerResult);
    };

    const handleImagePicked = async pickerResult => {
        try {
            if(!pickerResult.cancelled) {
            setIsUploading(true);
            const uploadUrl = await uploadImageAsync(pickerResult.uri, gameId);
            setIsUploading(false);
            finishSelection(uploadUrl, "m");
            } else {
                finishSelection(null);
            }
        }
        catch (e) {
            console.log(e);
            alert('Error uploading image.');
        } finally {
            finishSelection(null);
        }
    };

    const checkUrl = () => {
        setUrlError(null);
        let potentialUrl = urlText.toLowerCase();
          
        let last = potentialUrl.search(/[?&]/);
        if (last !== -1) potentialUrl = potentialUrl.substring(0, last);
        let extension = potentialUrl.substr(potentialUrl.length-4, 4);
        let beginning = potentialUrl.substr(0, 4);

        if(beginning === "http" &&(extension === ".jpg" || extension === ".png" || extension === ".gif")) {
            finishSelection(urlText, "m");
        } else {
            setUrlError("Error: URL must be an image of type JPG, PNG, or GIF.")
        }
    };

    const chooseSet = () => {

        let options = [];
        let tokenSetKeys = [];
        for(let j in TOKEN_SETS) {
            if(TOKEN_SETS[j].type === selectorType) {
                options.push(TOKEN_SETS[j].name);
                tokenSetKeys.push(j);
            }
        }
        options.push("Cancel");
        const cancelButtonIndex = options.length - 1;

        showActionSheetWithOptions({
            options,
            cancelButtonIndex
        }, (buttonIdx) => {
            if(buttonIdx == cancelButtonIndex) return;
            if(selectorType === "background") {
                globalStore.setState({
                    currentBgSet: tokenSetKeys[buttonIdx]
                });
            } else {
                globalStore.setState({
                    currentTokenSet: tokenSetKeys[buttonIdx]
                });
            }
        });
    }

    if(tokenAddType === "library") {
        for(let j in TOKEN_SETS[currentTokenSet].tokens) {
            let tokenImage = TOKEN_SETS[currentTokenSet].tokens[j];
            libraryListing.push(
                <TouchableOpacity style={{maxWidth: (selectorType === "background" ? 300 : 75), maxHeight: 75, marginLeft: 20, marginTop: 20}} onPress={() => finishSelection(tokenImage, TOKEN_SETS[currentTokenSet].defaultSize)} key={j}>
                    <Image source={{uri: tokenImage}} style={{width: (selectorType === "background" ? 300 : 75), height: 75, borderRadius: 75, resizeMode: "cover"}} />
                </TouchableOpacity>
            )
        }

    }

    let header = "Choose Image";

    return (
        <View
                style={{width: "100%", height: "100%", position: "absolute", backgroundColor: "rgba(255,255,255,0.65)", top: 0, left: 0, flex: 1, flexWrap: "wrap", flexDirection: "row", justifyContent: "center", alignContent: "center", zIndex: 20000}}
            >
            <View style={{position: "relative", backgroundColor: "#F1F1F1", borderRadius: 20, maxWidth: 420, minWidth: 300, marginLeft: 30, marginRight: 30, minHeight: 200, maxHeight: 500}}>
                <Text style={{paddingLeft: 20, paddingTop: 10, paddingBottom: 5, fontSize: 20}}>{header}</Text>
                <TouchableOpacity style={{position: "absolute", top: 10, right: 10}} onPress={() => props.callback(null)}>
                    <Ionicons name="close" style={{fontSize: 24}} />
                </TouchableOpacity>
                {tokenAddType == null &&
                    <View style={{padding: 20}}>
                        <View style={{paddingTop: 15}}>
                            <Button title="Choose From Library" onPress={() => setTokenAddType("library")} />
                        </View>
                        <View style={{paddingTop: 15}}>
                            <Button title="Share Image" onPress={() => pickImage()} />
                        </View>
                        <View style={{paddingTop: 15}}>
                            <Button title="Image from URL" onPress={() => setTokenAddType("url")} />
                        </View>
                    </View>
                }
                {tokenAddType == "library" &&
                <ScrollView>
                    <View style={{flex: 1, flexDirection: "row", flexWrap: "wrap", paddingBottom: 20}}>
                        {libraryListing}
                    </View>
                </ScrollView>  
                }
                {tokenAddType == "library" &&
                    <View style={{position: "absolute", top: 3, right: 50}}>
                        <Button title="Switch Set" onPress={chooseSet} />
                    </View>
                }
                {tokenAddType == "url" &&
                    <View style={{padding: 20}}>
                        <TextInput placeholder={"Enter Image URL..."} value={urlText} autoFocus={true} onChangeText={(newText) => setUrlText(newText)} style={{marginBottom: 20, ...tailwind("w-full px-3 py-2 border border-gray-300 text-gray-900 rounded-md text-base bg-white")}} />
                        <Button title="Save" onPress={() => checkUrl()} />
                        {urlError &&
                            <Text style={{paddingTop: 10, ...tailwind("text-red-600")}}>{urlError}</Text>
                        }
                    </View>
                }
            </View>
        </View>
    );

}